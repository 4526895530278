import { Header } from '@cmp'
import { FC } from 'react'

interface MainProps {}

export const Main: FC<MainProps> = ({ children }) => {
  return (
    <>
      <main className="container px-8 pb-16 mx-auto">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 xl:col-span-6 xl:col-start-4">
            <Header />
            {children}
          </div>
        </div>
      </main>
    </>
  )
}

export default Main
