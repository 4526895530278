import S from 'react-loading-skeleton'

interface Props {
  date?: boolean
}

export const LoadingTemplate = ({ date }: Props) => (
  <div>
    {date && (
      <p className="mb-2 font-light text-lg">
        <S width="202px" />
      </p>
    )}
    <h1 className="text-h4 md:text-h2 text-primary font-semibold mb-6">
      <S />
    </h1>
    <p className="mb-8 font-light text-lg">
      <S count={3} />
    </p>
  </div>
)

export default LoadingTemplate
