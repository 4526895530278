import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// Create a client
import { BrowserRouter as Router } from 'react-router-dom'
import { Main } from '@layout'
import Routes from './Routes'

const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Main>
          <Routes />
        </Main>
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
