import React, { forwardRef } from 'react'
import cn from 'classnames'

interface Props {
  full?: boolean
  size?: 'small' | 'default'
  ghost?: boolean
  loading?: boolean
}

export const buttonClassName = ({
  className,
  full,
  disabled,
  ghost,
  size = 'default',
}: {
  className?: string
  full?: Props['full']
  disabled?: boolean
  ghost?: Props['ghost']
  size?: Props['size']
}) =>
  cn(
    className,
    'relative text-button rounded-full focus:outline-none transition-color-shadow',
    {
      'w-full': full,
      'bg-grey cursor-default': disabled,
      'text-gray bg-secondary hover:bg-secondary-dark focus:shadow-button':
        !disabled && !ghost,
      'pt-2.5 pb-2 px-4': size === 'small',
      'py-4 px-8 md:py-4 md:px-12': size === 'default',
      'border-2 hover:bg-grey-light focus:shadow-button-ghost':
        !disabled && ghost,
    }
  )

export const Button = forwardRef<
  HTMLButtonElement,
  Props & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      full,
      className,
      size = 'default',
      ghost,
      loading,
      disabled,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <button
        disabled={disabled || loading}
        className={buttonClassName({
          className,
          full,
          disabled: disabled || loading,
          ghost,
          size,
        })}
        ref={ref}
        {...buttonProps}
      >
        {loading ? (
          <>
            <span className="text-transparent">{children}</span>
            <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
              <div className="w-4 h-4 rounded-full bg-current animate-beat mr-4" />
              <div className="w-4 h-4 rounded-full bg-current animate-beat animation-delay-400 mr-4" />
              <div className="w-4 h-4 rounded-full bg-current animate-beat animation-delay-800" />
            </div>
          </>
        ) : (
          children
        )}
      </button>
    )
  }
)

export default Button
