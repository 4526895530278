import { Button } from '@cmp'
import React from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import cn from 'classnames'
import Logo from '../../assets/Samtokin-78_isl_Landscape_RGB.webp'
import LogoMobile from '../../assets/78_RGB.webp'

export const Header = () => {
  const token = Cookies.get('auth_cookie')
  const jwt = token ? jwt_decode<{ sub: string; ssn: string }>(token) : null
  return (
    <div
      className={cn(
        'py-10 flex mb-10 items-center',
        jwt ? 'justify-between' : 'justify-start'
      )}
    >
      <Link to="/">
        <img
          src={Logo}
          alt="Samtökin 78 kosningar"
          className="w-80 hidden md:block"
        />
        <img
          src={LogoMobile}
          alt="Samtökin 78 kosningar"
          className="w-20 md:hidden"
        />
      </Link>
      {jwt && (
        <div className="text-right">
          <span className="mr-5 md:text-lg font-light">
            {jwt.sub.split(' ')[0]}
          </span>
          <Button
            ghost
            size="small"
            onClick={() => {
              window.location.replace('/')
              Cookies.remove('auth_cookie')
            }}
          >
            Útskrá
          </Button>
        </div>
      )}
    </div>
  )
}

export default Header
