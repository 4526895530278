import { LoadingTemplate } from '@cmp'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import React, { lazy, Suspense } from 'react'
import { Switch, Route, RouteProps, Redirect } from 'react-router-dom'

// Route components
const Login = lazy(() => import('./pages/Login'))
const EditElection = lazy(() => import('./pages/EditElection'))
const NewElection = lazy(() => import('./pages/NewElection'))
const Election = lazy(() => import('./pages/Election'))
const Home = lazy(() => import('./pages/Home'))

const Routes = () => (
  <Suspense fallback={<LoadingTemplate />}>
    <Switch>
      <Route path="/login" component={Login} />
      <AdminRoute path="/edit-election/:id" component={EditElection} />
      <AdminRoute path="/new-election" component={NewElection} />
      <Route path="/election/:id" component={Election} />
      <Route path="/" component={Home} />
    </Switch>
  </Suspense>
)

const AdminRoute = (routeProps: RouteProps) => {
  const token = Cookies.get('auth_cookie')
  const jwt = token ? jwt_decode<{ roles: ('admin' | 'user')[] }>(token) : null
  if (jwt?.roles && jwt.roles.indexOf('admin') >= 0) {
    return <Route {...routeProps} />
  }

  return (
    <Redirect
      to={{
        pathname: '/',
      }}
    />
  )
}

export default Routes
